var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vxe-modal",
    {
      attrs: {
        width: "350",
        height: "auto",
        showHeader: false,
        "show-close": false,
        "show-zoom": false,
        resize: "",
        position: "center",
      },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          {
            staticClass: "header",
            style: _vm.infoType === "error" ? "color:red" : "",
          },
          [_vm._v(_vm._s(_vm.dialogTitle))]
        ),
        _c(
          "div",
          { staticClass: "info-list" },
          _vm._l(_vm.infoList, function (item, index) {
            return _c("div", { key: index, staticClass: "info-list-item" }, [
              _c("span", [_vm._v(_vm._s(item.label) + "：")]),
              _c("span", [_vm._v(_vm._s(item.value))]),
            ])
          }),
          0
        ),
        _c("div", { staticClass: "link", on: { click: _vm.handleRedirect } }, [
          _vm._v(" " + _vm._s(_vm.linkLabel) + " "),
        ]),
        _c(
          "div",
          { staticClass: "bottom" },
          [
            _vm.showCanncel
              ? _c(
                  "a-button",
                  {
                    staticClass: "btn",
                    on: {
                      click: function ($event) {
                        _vm.show = false
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.cancelText))]
                )
              : _vm._e(),
            _c(
              "a-button",
              {
                staticClass: "btn",
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    _vm.show = false
                  },
                },
              },
              [_vm._v(_vm._s(_vm.confirmText))]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }