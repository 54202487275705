<template>
  <vxe-modal
    v-model="show"
    width="350"
    height="auto"
    :showHeader="false"
    :show-close="false"
    :show-zoom="false"
    resize
    position="center"
  >
    <div class="container">
      <div class="header" :style="infoType === 'error' ? 'color:red' : ''">{{ dialogTitle }}</div>
      <div class="info-list">
        <div v-for="(item, index) in infoList" :key="index" class="info-list-item">
          <span>{{ item.label }}：</span>
          <span>{{ item.value }}</span>
        </div>
      </div>
      <div class="link" @click="handleRedirect">
        {{ linkLabel }}
      </div>
      <div class="bottom">
        <a-button v-if="showCanncel" class="btn" @click="show = false">{{ cancelText }}</a-button>
        <a-button class="btn" type="primary" @click="show = false">{{ confirmText }}</a-button>
      </div>
    </div>
  </vxe-modal>
</template>

<script>
export default {
  data() {
    return {
      // 对话框标题
      dialogTitle: '',
      confirmText: '确定',
      cancelText: '取消',
      showCancel: false,
      // info-信息提示，warn-警告，error-报错
      infoType: 'info',
      // 信息列表
      infoList: [],
      // 外部链接
      linkUrl: '',
      // 标记链接文本
      linkLabel: '',
      show: false
    }
  },
  methods: {
    handleRedirect() {
      this.$router.push(this.linkUrl)
    },
    open(data) {
      if (data) {
        Object.keys(data).forEach((p) => {
          this[p] = data[p]
        })
        console.log('infoList', this.infoList)
        this.show = true
      }
    },
    close() {
      this.show = false
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  padding: 10px;
}
.header {
  text-align: center;
  padding-bottom: 10px;
  font-size: 18px;
}
.info-list {
  min-height: 100px;
  .info-list-item {
    span:first-child {
      font-weight: 600;
    }
    margin-bottom: 5px;
  }
}
.link {
  padding: 10px 0;
  color: #0079ff;
  text-decoration: underline;
  cursor: pointer;
}
.bottom {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  .btn:first-child {
    padding-right: 20px;
  }
  .btn {
    flex: 1;
  }
}
</style>
